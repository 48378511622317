@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-transparent: 0deg 0% 100%;
    /* ... */
  }
}
